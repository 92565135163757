<template>
    <div class="d-flex h-screen main-wrapper">
      <vMenu />
      <main class="flex-grow-1 main">
        <div class="main__content">
          <router-view
            @dragFileEnter="showDropArea = true"
            @setFile="setFile"
            :uploadFile="droppedFile"
          />
        </div>
      </main>
      <Transition name="fade">
        <vDropFileArea v-if="showDropArea" @leave="showDropArea = false" @dropFile="setFile" />
      </Transition>
    </div>
  </template>
  
  <script>
    import vDropFileArea from "@/components/vDropFileArea.vue";
    import vMenu from "@/components/vMenuBlockComponent.vue";
  
    export default {
      name: "vMainTemplate",
      components: {
        vMenu,
        vDropFileArea,
      },
      data: () => ({
        showDropArea: false,
        droppedFile: null,
      }),
      mounted() {
        const username = window.$cookies.get("username");

        if (!username) {
          return;
        }

        this.$store.commit("setUserData", { key: "username", val: username, });
      },
      methods: {
        setFile(val) {
          this.showDropArea = false;
          this.droppedFile = val;
        },
      },
    }
  </script>
  
<style scoped lang="scss" src="@/assets/scss/main.scss"></style>