<template>
  <div class="d-flex flex-column chat-input-query">
    <v-form @submit.prevent="sendMessage" class="chat-input-query__form" enctype="multipart/form-data">
      <div v-if="file" class="d-flex justify-space-between chat-input-query__upload-files">
        <div class="d-flex align-center upload-file-card">
          <div class="mr-2 upload-file-card__name">{{ file.name }}</div>
          <button @click="$emit('setFile', null);" class="d-flex align-center justify-center  upload-file-card__btn">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.49928 6.49865C6.83401 6.16392 7.6284 6.1665 7.96313 6.50123L11.9985 10.4989L15.9985 6.49889C16.3333 6.16415 17.1638 6.1665 17.4985 6.50123C17.8333 6.83597 17.8333 7.66415 17.4985 7.99889L13.4985 11.9989L17.4985 15.9989C17.8333 16.3336 17.8333 17.1642 17.4985 17.4989C17.1638 17.8336 16.3714 17.8336 16.0367 17.4989L11.9985 13.4989L7.99854 17.4989C7.6638 17.8336 6.83327 17.8336 6.49854 17.4989C6.1638 17.1642 6.16665 16.3336 6.50139 15.9989L10.4985 11.9989L6.4999 8C6.16516 7.66526 6.16454 6.83339 6.49928 6.49865Z" fill="#8E8E8E"/>
            </svg>
          </button>
        </div>
      </div>
      <div class="d-flex align-center justify-space-between chat-input-query__wrapper">
        <div class="chat-input-query__control" v-if="getActiveChat !== 'image'">
          <label class="upload-file-area" for="chat-file">
            <input 
              @change="uploadFile($event)" 
              class="upload-file-area__input" 
              id="chat-file" 
              type="file" 
              name="file" 
              :accept="getValidExtensions.map(({ name, }) => name).join(',')"
            />
            <svg class="chat-input-query__control-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.7499 3C14.7076 3 13.6815 3.40438 12.8906 4.19531L5.85931 11.2266C3.63015 13.4557 3.63031 17.0826 5.85931 19.3125C8.08848 21.5417 11.7154 21.5415 13.9452 19.3125L18.1054 15.1523C18.3966 14.8611 18.3966 14.3889 18.1054 14.0977C17.8142 13.8064 17.342 13.8064 17.0507 14.0977L12.8906 18.2578C11.2339 19.9138 8.56983 19.9136 6.914 18.2578C5.258 16.6012 5.25816 13.9371 6.914 12.2812L13.9452 5.25C14.9539 4.24136 16.5694 4.24136 17.5781 5.25C18.5867 6.25864 18.5867 7.87417 17.5781 8.88281L10.5468 15.9141C10.1854 16.275 9.61822 16.2745 9.25775 15.9141C8.89678 15.5527 8.89727 14.9855 9.25775 14.625L15.1757 8.70703C15.467 8.41579 15.467 7.94359 15.1757 7.65234C14.8845 7.3611 14.4123 7.3611 14.121 7.65234L8.20306 13.5703C7.26904 14.5043 7.26953 16.0341 8.20306 16.9688C9.13709 17.9028 10.6669 17.9023 11.6015 16.9688L18.6327 9.9375C20.2146 8.35564 20.2146 5.77717 18.6327 4.19531C17.8418 3.40438 16.7923 3 15.7499 3Z" fill="#8E8E8E"/>
            </svg>
          </label>
        </div>
        <div 
          class="d-flex justify-center align-center chat-input-query__text" 
          :class="{ 'mx-4': getActiveChat !== 'image', 'mr-4': getActiveChat === 'image' }"
        >
          <textarea
            v-model="message"
            @keydown="nextLine($event)"
            ref="textarea"
            placeholder="Введите запрос…"
            class="overflow-x-auto chat-input-query__text-area"
            rows="1"
          ></textarea>
        </div>
        <div class="chat-input-query__control">
          <button 
            class="d-flex align-center justify-center chat-input-query__control-btn chat-input-query__control-btn--send" 
            @click="sendMessage" 
            type="submit"
            :disabled="!getTrimMessage.length"
          >
            <svg class="chat-input-query__control-icon chat-input-query__control-icon--send" width="24" height="24" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24.0117 13.2364L5.53664 2.89037C5.38122 2.80333 5.20293 2.7658 5.0256 2.7828C4.84828 2.79981 4.68037 2.87053 4.54431 2.98551C4.40826 3.1005 4.31054 3.25427 4.26422 3.42628C4.21789 3.59829 4.22518 3.78034 4.28509 3.9481L7.7699 13.7056C7.83786 13.8959 7.83786 14.1038 7.7699 14.2941L4.28509 24.0516C4.22518 24.2193 4.21789 24.4014 4.26422 24.5734C4.31054 24.7454 4.40826 24.8992 4.54431 25.0142C4.68037 25.1292 4.84828 25.1999 5.0256 25.2169C5.20293 25.2339 5.38122 25.1964 5.53664 25.1093L24.0117 14.7633C24.1474 14.6873 24.2604 14.5765 24.3391 14.4423C24.4177 14.3081 24.4592 14.1554 24.4592 13.9998C24.4592 13.8443 24.4177 13.6916 24.3391 13.5574C24.2604 13.4232 24.1474 13.3124 24.0117 13.2364V13.2364Z" stroke="#ED131C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.875 14H14.875" stroke="#ED131C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import autosize from "autosize";

export default {
  name: "vInputChatComponent",
  computed: {
    getAIVersion() {
      return this.$store.getters.getAIVersion;
    },
    getActiveChat() {
      return this.$store.getters.getActiveChat;
    },
    getUsername() {
      return this.$store.getters.getUserData.username;
    },
    getValidExtensions() {
      return this.$store.getters.getValidExtensions;
    },
    getTrimMessage() {
      return this.message.trim();
    },
  },
  props: {
    file: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    message: "",
  }),
  mounted() {
    this.$nextTick(() => {
      autosize(this.$refs.textarea);
    });
  },
  watch: {
    message() {
      this.$nextTick(() => {
        autosize.update(this.$refs.textarea);
      });
    }
  },
  methods: {
    nextLine(e) {
      const key = e.key.toLowerCase();

      if (key === "enter") {
        e.preventDefault();

        if (e.shiftKey) {
          const textarea = e.currentTarget;
          const activeIdx = textarea.selectionStart - 1;
          const activeSymbol = this.message[activeIdx] || "";
          const cloneMess = this.message.split("");

          cloneMess.splice(activeIdx, 1, `${activeSymbol}\n`);

          this.message = cloneMess.join("");
          
          textarea.scrollTop = textarea.scrollHeight;
          
          return;
        }

        this.sendMessage();

        this.message = "";
      }
    },
    sendMessage() {
      if (!this.getTrimMessage.length) {
        return;
      }

      const typeData = this.getActiveChat !== "image" ? "text" : "image";

      this.$emit("new-message", {
        version: this.getAIVersion,
        chat: this.getActiveChat,
        message: this.getTrimMessage,
        file: this.file,
        username: this.getUsername,
        type_data: typeData,
      });

      this.message = "";

      this.$emit("setFile", null);
    },
    uploadFile(e) {
      const file = e.currentTarget.files[0];

      this.$emit("setFile", file);

      // Сброс значения файла
      e.target.value = null;
    }
  }
}
</script>

<style lang="scss" src="@/assets/scss/InputChatComponent.scss"></style>