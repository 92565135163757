import { createStore, } from "vuex";

export default createStore({
    state: {
        aiVersion: "gpt-4o",
        chat: "assisten",
        theme: "light",
        showSideMenu: false,
        validExtensions: [
            {
                name: ".docx",
                value: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            },
            {
                name: ".doc",
                value: "application/msword",
            },
            {
                name: ".dot",
                value: "application/msword",
            },
            {
                name: ".docm",
                value: "application/vnd.ms-word.document.macroEnabled.12",
            },
            {
                name: ".dotx",
                value: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
            },
            {
                name: ".dotm",
                value: "application/vnd.ms-word.template.macroEnabled.12",
            },
            {
                name: ".rtf",
                value: "application/rtf",
            },
            {
                name: ".txt",
                value: "text/plain",
            },
            {
                name: ".text",
                value: "text/plain",
            },
            {
                name: ".text",
                value: "text/plain",
            },
            {
                name: ".pdf",
                value: "application/pdf",
            },
            {
                name: ".log",
                value: "text/plain",
            },
            {
                name: ".conf",
                value: "text/plain",
            },
            {
                name: ".csv",
                value: "text/csv",
            },
            {
                name: ".xlsx",
                value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
            {
                name: ".xls",
                value: "application/vnd.ms-excel",
            }
        ],
        userData: {},
        chats: {
            assisten: [],
            image: [],
            code: [],
        },
    },
    getters: {
        getAIVersion: (state) => {
            if (state.chat === "image") {
                return "dall-e-3";
            }

            return state.aiVersion;
        },
        getValidExtensions: (state) => state.validExtensions,
        getActiveChatMessages: (state) => state.chats[state.chat] || [],
        getActiveChat: (state) => state.chat,
        getShowSideMenu: (state) => state.showSideMenu,
        getTheme: (state) => state.theme,
        getUserData: (state) => state.userData,
    },
    mutations: {
        addMessageToActiveChat(state, message) {
            state.chats[state.chat].push(message);
        },
        editMessageInActiveChat(state, { key, value, index, }) {
            const activeMessage = state.chats[state.chat][index];

            state.chats[state.chat].splice(index, 1, { ...activeMessage, [key]: value, });
        },
        clearAllChats(state) {
            state.chats = {
                assisten: [],
                image: [],
                code: [],
            };
        },
        setShowSideMenu(state, val) {
            state.showSideMenu = val;
        },
        setUserData(state, { key, val, }) {
            state.userData[key] = val;
        },
        clearUserData(state) {
            state.userData = {};
        },
        setActiveChat(state, val) {
            state.chat = val;
        },
        setTheme(state, val) {
            state.theme = val;

            localStorage.setItem("theme", val);
        }
    }
});