<template>
    <div class="drop-file-area" @click="close($event)">
        <div 
            class="drop-file-area__wrapper" 
            @dragleave="onDragLeave($event)"
            @dragover.prevent
            @drop.prevent="onDrop($event)"
        >
            <div class="d-flex justify-center flex-column align-center text-center drop-file-area__content">
                <h3 class="mb-2 drop-file-area__title">{{ title }}</h3>
                <p class="drop-file-area__desc" v-html="desc"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "vDropFileArea",
    computed: {
        getValidExtensions() {
            return this.$store.getters.getValidExtensions;
        },
    },
    data: () => ({
        title: "",
        desc: "",
    }),
    mounted() {
        this.title = "Перетащите сюда файл";
        this.desc = "для его последующей отправки";
    },
    methods: {
        close(e) {
            if (!e.target.classList.contains("drop-file-area")) {
                return;
            }

            this.$emit("leave");
        },
        onDrop(e) {
            const files = e.dataTransfer.files;

            if (files.length > 1) {
                this.title = "Внимание";
                this.desc = "можно прикрепить только 1 файл!";

                return;
            }

            const [file] = files;

            if (!this.getValidExtensions.map(({ value, }) => value).includes(file.type)) {
                const strExtensions = this.getValidExtensions.map(({ name, }) => `<b>${name}</b>`);

                this.title = "Внимание";
                this.desc = `можно прикрепить файл только с указанным расширением: ${strExtensions.join(", ")}`;

                return;
            }

            this.title = "Успешно";
            this.desc = "файл был прикреплен";

            this.$emit("dropFile", file);
        },
        onDragLeave(e) {
            if (!e.target.classList.contains("drop-file-area__wrapper")) {
                return;
            }
            
            this.$emit("leave");
        },
    }
}
</script>

<style scoped lang="scss" src="@/assets/scss/dropFileArea.scss"></style>